// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-cn {
  position: relative;
  display: inline-flex;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  height: 26px;
  width: 55px;
  border-radius: 22px;
}



.radio-cn input[type=radio] {
  display: none;
}

.radio-cn:has(input:checked){
    background-color: #0090DA;
}

.radio-selector{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    transition: all .4s 0s ease;
    background-color: #FFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.r-selected-0 {
    left: calc(100% - 24px);
}

.r-selected-1 {
    left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/Radio/radioButton.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,oCAAoC;EACpC,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;;;AAIA;EACE,aAAa;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,2BAA2B;IAC3B,sBAAsB;IACtB,0CAA0C;AAC9C;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,SAAS;AACb","sourcesContent":[".radio-cn {\n  position: relative;\n  display: inline-flex;\n  border: none;\n  background-color: rgba(0, 0, 0, 0.4);\n  height: 26px;\n  width: 55px;\n  border-radius: 22px;\n}\n\n\n\n.radio-cn input[type=radio] {\n  display: none;\n}\n\n.radio-cn:has(input:checked){\n    background-color: #0090DA;\n}\n\n.radio-selector{\n    position: absolute;\n    width: 20px;\n    height: 20px;\n    top: 3px;\n    transition: all .4s 0s ease;\n    background-color: #FFF;\n    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);\n}\n\n.r-selected-0 {\n    left: calc(100% - 24px);\n}\n\n.r-selected-1 {\n    left: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
