// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ferris-select-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    --ferris-opt-size: 4rem;
}

.ferris-select-wheel-perim{
    position: absolute;
    height: 100%;
    left: 50%;
    padding: var(--ferris-opt-size);
    border-radius: 999999px;
}

.ferris-select-wheel{
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 999999px;
}

.ferris-select-ind-wheel{
    position: absolute;
    width: 75%;
    height: 75%;
    left: 12.5%;
    top: 12.5%;
    border-radius: 99999px;
}

.ferris-select-wheel::after{
    position: absolute;
    width: calc(77.5% - 0.5rem);
    height: calc(77.5% - 0.5rem);
    left: calc(11.25% + 0.25rem);
    top: calc(11.25% + 0.25rem);
    border-radius: 99999px;
    content:'';
    border: 1rem solid rgba(0,0,0,0.1);
}

.ferris-select-ind{
    height: 1rem;
    width: 1rem;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    border-radius: 99999px;
    background: rgba(0,0,0,0.2);
}


.ferris-select-wheel > *:not(#ferris-select-ind-wheel){
    height: var(--ferris-opt-size);
    width: var(--ferris-opt-size);
}

.ferris-select-opt{
    margin-left: calc(var(--ferris-opt-size)/-2);
    margin-top: calc(var(--ferris-opt-size)/-2);
}

`, "",{"version":3,"sources":["webpack://./src/components/Input/FerrisSelect/ferrisSelect.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,+BAA+B;IAC/B,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,WAAW;IACX,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,4BAA4B;IAC5B,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;IACtB,UAAU;IACV,kCAAkC;AACtC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,oBAAoB;IACpB,sBAAsB;IACtB,2BAA2B;AAC/B;;;AAGA;IACI,8BAA8B;IAC9B,6BAA6B;AACjC;;AAEA;IACI,4CAA4C;IAC5C,2CAA2C;AAC/C","sourcesContent":["\n\n.ferris-select-wrapper{\n    position: relative;\n    width: 100%;\n    height: 100%;\n    --ferris-opt-size: 4rem;\n}\n\n.ferris-select-wheel-perim{\n    position: absolute;\n    height: 100%;\n    left: 50%;\n    padding: var(--ferris-opt-size);\n    border-radius: 999999px;\n}\n\n.ferris-select-wheel{\n    position: relative;\n    height: 100%;\n    width: 100%;\n    border-radius: 999999px;\n}\n\n.ferris-select-ind-wheel{\n    position: absolute;\n    width: 75%;\n    height: 75%;\n    left: 12.5%;\n    top: 12.5%;\n    border-radius: 99999px;\n}\n\n.ferris-select-wheel::after{\n    position: absolute;\n    width: calc(77.5% - 0.5rem);\n    height: calc(77.5% - 0.5rem);\n    left: calc(11.25% + 0.25rem);\n    top: calc(11.25% + 0.25rem);\n    border-radius: 99999px;\n    content:'';\n    border: 1rem solid rgba(0,0,0,0.1);\n}\n\n.ferris-select-ind{\n    height: 1rem;\n    width: 1rem;\n    margin-top: -0.5rem;\n    margin-left: -0.5rem;\n    border-radius: 99999px;\n    background: rgba(0,0,0,0.2);\n}\n\n\n.ferris-select-wheel > *:not(#ferris-select-ind-wheel){\n    height: var(--ferris-opt-size);\n    width: var(--ferris-opt-size);\n}\n\n.ferris-select-opt{\n    margin-left: calc(var(--ferris-opt-size)/-2);\n    margin-top: calc(var(--ferris-opt-size)/-2);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
