// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnc-logo{
    display:none;
}

@media only screen and (max-width: 1199px) {

    .myleft-actions-banner{
        flex-direction: row !important;
        justify-content: space-between;
    }

    .banner-vote-wrapper > a{
        font-size: 0.4rem;
        padding: 7px 6px 5px;
    }

    .banner-logo{
        height: 3rem !important;
    }

    .banner-logo-link{
        left: 0 !important;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: relative !important;
    }

    .cd-item-value{
        font-size: 1rem;
        width: 1rem;
    }

    .cd-cn > :nth-child(n):before{
        font-size: 0.5rem;
    }

    .cd-msg{
        font-size: 0.75rem;
    }

    .cd-wrapper::before{
        display:none;
    }

    .rnc-logo{
        display: block;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/container/banner/banner.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;;IAEI;QACI,8BAA8B;QAC9B,8BAA8B;IAClC;;IAEA;QACI,iBAAiB;QACjB,oBAAoB;IACxB;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;QAClB,oBAAoB;QACpB,qBAAqB;QACrB,6BAA6B;IACjC;;IAEA;QACI,eAAe;QACf,WAAW;IACf;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,cAAc;IAClB;;AAEJ","sourcesContent":[".rnc-logo{\n    display:none;\n}\n\n@media only screen and (max-width: 1199px) {\n\n    .myleft-actions-banner{\n        flex-direction: row !important;\n        justify-content: space-between;\n    }\n\n    .banner-vote-wrapper > a{\n        font-size: 0.4rem;\n        padding: 7px 6px 5px;\n    }\n\n    .banner-logo{\n        height: 3rem !important;\n    }\n\n    .banner-logo-link{\n        left: 0 !important;\n        padding-left: 0.5rem;\n        padding-right: 0.5rem;\n        position: relative !important;\n    }\n\n    .cd-item-value{\n        font-size: 1rem;\n        width: 1rem;\n    }\n\n    .cd-cn > :nth-child(n):before{\n        font-size: 0.5rem;\n    }\n\n    .cd-msg{\n        font-size: 0.75rem;\n    }\n\n    .cd-wrapper::before{\n        display:none;\n    }\n\n    .rnc-logo{\n        display: block;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
