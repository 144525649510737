// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-modal-cn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(0deg, var(--glory-red) 20%, var(--glory-secondary-red) 70%);
}

.payment-modal-cn.cs-loader-center * {
    pointer-events: none !important;
}

.payment-modal-cn > .modal-content-cn{
    display: flex;
    height: 700px;
    max-height: 700px;
    min-height: 700px;
    width: 500px;
    min-width: 500px;
    justify-content: center;
    align-items: center;
}

.payment{
    width: 100%;
    height:100%;
}

.payment-form-pg  label > span {
    display: none;
}


.payment-form-pg{
    position:relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background: transparent;
    overflow:hidden;
}

.payment-form-pg > div:first-child{
    flex: 1 1;
}
.payment-form-cn > div {
    display: flex;
    background: transparent;
    flex: 1 1;
    overflow: hidden;
}
.payment-form-cn{
    background: transparent !important;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/payment/payment.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uFAAuF;AAC3F;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,SAAO;AACX;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAY;IACZ,gBAAgB;AACpB;AACA;IACI,kCAAkC;IAClC,UAAU;AACd","sourcesContent":[".payment-modal-cn{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    background: linear-gradient(0deg, var(--glory-red) 20%, var(--glory-secondary-red) 70%);\n}\n\n.payment-modal-cn.cs-loader-center * {\n    pointer-events: none !important;\n}\n\n.payment-modal-cn > .modal-content-cn{\n    display: flex;\n    height: 700px;\n    max-height: 700px;\n    min-height: 700px;\n    width: 500px;\n    min-width: 500px;\n    justify-content: center;\n    align-items: center;\n}\n\n.payment{\n    width: 100%;\n    height:100%;\n}\n\n.payment-form-pg  label > span {\n    display: none;\n}\n\n\n.payment-form-pg{\n    position:relative;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    max-height: 100%;\n    background: transparent;\n    overflow:hidden;\n}\n\n.payment-form-pg > div:first-child{\n    flex: 1;\n}\n.payment-form-cn > div {\n    display: flex;\n    background: transparent;\n    flex: 1 1 0%;\n    overflow: hidden;\n}\n.payment-form-cn{\n    background: transparent !important;\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
