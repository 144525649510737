// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary-button{
    background: white;
    color: #333;
    box-shadow: var(--btn-shadow);
    font-weight: 550;
}

.secondary-button:hover{
    background: var(--brand-dark-green);
    color: white;
    font-size: 500;
}

.secondary-button:focus{
    background: var(--brand-dark-green);
    color: white;
    font-size: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/Secondary/secondaryButton.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,mCAAmC;IACnC,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,mCAAmC;IACnC,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".secondary-button{\n    background: white;\n    color: #333;\n    box-shadow: var(--btn-shadow);\n    font-weight: 550;\n}\n\n.secondary-button:hover{\n    background: var(--brand-dark-green);\n    color: white;\n    font-size: 500;\n}\n\n.secondary-button:focus{\n    background: var(--brand-dark-green);\n    color: white;\n    font-size: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
