// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-cn{
    border-radius: 0.5rem;
}

.modal-content-cn{
    overflow-y: scroll;
    max-height: 80%;
    width: 100%;
}

.modal-content-cn > div:first-child{
    height: 100%;
    width: 100%;
}

.modal-title {
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    font-family: 'Lato';
    font-weight: 500;
    letter-spacing: 1px;
    background: var(--glory-red);
}


.modal-title::before{
    content: '';
    background-color: white;
    height: 0.5rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.modal-title > p {
    align-self: center;
    font-size: 1.5rem;
    color: white;
    flex: 1 1;
    text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/modal.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,4BAA4B;AAChC;;;AAGA;IACI,WAAW;IACX,uBAAuB;IACvB,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,SAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".modal-cn{\n    border-radius: 0.5rem;\n}\n\n.modal-content-cn{\n    overflow-y: scroll;\n    max-height: 80%;\n    width: 100%;\n}\n\n.modal-content-cn > div:first-child{\n    height: 100%;\n    width: 100%;\n}\n\n.modal-title {\n    position: relative;\n    padding: 1rem;\n    border-bottom: 1px solid #ccc;\n    font-family: 'Lato';\n    font-weight: 500;\n    letter-spacing: 1px;\n    background: var(--glory-red);\n}\n\n\n.modal-title::before{\n    content: '';\n    background-color: white;\n    height: 0.5rem;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 3;\n}\n\n.modal-title > p {\n    align-self: center;\n    font-size: 1.5rem;\n    color: white;\n    flex: 1 1 0%;\n    text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
