// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  position: relative;
  height: 5rem;
  width: 5rem;
}

.loading-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 5px solid #ACCAFF36;
  border-radius: 50%;
  
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #ACCAFF;
    top: -5px;
    left: -5px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loaders/basic/basicLoader.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EAEX,2BAA2B;EAC3B,kBAAkB;;EAElB;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,6BAA6B;IAC7B,yBAAyB;IACzB,SAAS;IACT,UAAU;IACV,kCAAkC;EACpC;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading-container {\n  position: relative;\n  height: 5rem;\n  width: 5rem;\n}\n\n.loading-progress {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  border-radius: 50%;\n  border: 5px solid #ACCAFF36;\n  border-radius: 50%;\n  \n  &::before {\n    content: \"\";\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    border-radius: 50%;\n    border: 5px solid transparent;\n    border-top-color: #ACCAFF;\n    top: -5px;\n    left: -5px;\n    animation: spin 1s linear infinite;\n  }\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  \n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
