// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea-input-cn{
    position: relative;
    outline: 1px solid #ccc;
    padding: 2px 4px;
}

.textarea-input-cn:has(input:disabled){
    outline-color: #BBB;
    background-color: #EEE;
    pointer-events: none;
}

.textarea-input{  
    outline: none;
    border: none;
    background-color: transparent;
    text-align: center;
    min-width: 0;
}

.textarea-input-rounded{
    border-radius: 24px;
    padding: 2px 14px;
}

.textarea-input:focus{
    text-align: start;
}


.textarea-input-cn:has(input:invalid){
    outline: 1px solid;
    outline-color: #DB095B;
}

`, "",{"version":3,"sources":["webpack://./src/components/Input/Textarea/textarea.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".textarea-input-cn{\n    position: relative;\n    outline: 1px solid #ccc;\n    padding: 2px 4px;\n}\n\n.textarea-input-cn:has(input:disabled){\n    outline-color: #BBB;\n    background-color: #EEE;\n    pointer-events: none;\n}\n\n.textarea-input{  \n    outline: none;\n    border: none;\n    background-color: transparent;\n    text-align: center;\n    min-width: 0;\n}\n\n.textarea-input-rounded{\n    border-radius: 24px;\n    padding: 2px 14px;\n}\n\n.textarea-input:focus{\n    text-align: start;\n}\n\n\n.textarea-input-cn:has(input:invalid){\n    outline: 1px solid;\n    outline-color: #DB095B;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
