// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button{
    background-color: white;
    box-shadow: var(--btn-shadow);
    outline: 1px #333 solid;
    font-weight: 550;
    font-family: Arial;
    color: #333;
}

.primary-button:hover{
    background-color: #333;
    color: white;
    outline: 1px white solid;
}

.primary-button:focus{
    background-color: #333;
    color: white;
    outline: 1px white solid;
}

.close-btn{
    padding: 0px;
    width: 1.75rem !important;
    height: 1.75rem !important;
    outline: none !important;
    background: #ddd !important;
    border: none !important;
    box-shadow: none !important;
    object-fit: contain;
    transition: opacity 250ms ease-in-out;
    opacity: 70%;
    
}

.close-btn:hover {
    opacity: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/Primary/primaryButton.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,6BAA6B;IAC7B,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,0BAA0B;IAC1B,wBAAwB;IACxB,2BAA2B;IAC3B,uBAAuB;IACvB,2BAA2B;IAC3B,mBAAmB;IACnB,qCAAqC;IACrC,YAAY;;AAEhB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".primary-button{\n    background-color: white;\n    box-shadow: var(--btn-shadow);\n    outline: 1px #333 solid;\n    font-weight: 550;\n    font-family: Arial;\n    color: #333;\n}\n\n.primary-button:hover{\n    background-color: #333;\n    color: white;\n    outline: 1px white solid;\n}\n\n.primary-button:focus{\n    background-color: #333;\n    color: white;\n    outline: 1px white solid;\n}\n\n.close-btn{\n    padding: 0px;\n    width: 1.75rem !important;\n    height: 1.75rem !important;\n    outline: none !important;\n    background: #ddd !important;\n    border: none !important;\n    box-shadow: none !important;\n    object-fit: contain;\n    transition: opacity 250ms ease-in-out;\n    opacity: 70%;\n    \n}\n\n.close-btn:hover {\n    opacity: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
