// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subdomain input[type="text"]{
    text-align: center;
}

label:has(.subdomain){
    grid-template-columns: 200px 200px auto;
}
label:has(.subdomain)::after{
    content: ".bev.me";
    position: relative;
    height: 100%;
    align-items: center;
    display: flex;
    border-right: 2px solid var(--primary-blue);
    border-top: 2px solid var(--primary-blue);
    border-bottom: 2px solid var(--primary-blue);
    padding: 0px 8px 0px 8px ;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--secondary-blue);
    color: white;
    font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Subdomain/subdomain.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;AAC3C;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,2CAA2C;IAC3C,yCAAyC;IACzC,4CAA4C;IAC5C,yBAAyB;IACzB,4BAA4B;IAC5B,+BAA+B;IAC/B,uCAAuC;IACvC,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".subdomain input[type=\"text\"]{\n    text-align: center;\n}\n\nlabel:has(.subdomain){\n    grid-template-columns: 200px 200px auto;\n}\nlabel:has(.subdomain)::after{\n    content: \".bev.me\";\n    position: relative;\n    height: 100%;\n    align-items: center;\n    display: flex;\n    border-right: 2px solid var(--primary-blue);\n    border-top: 2px solid var(--primary-blue);\n    border-bottom: 2px solid var(--primary-blue);\n    padding: 0px 8px 0px 8px ;\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n    background-color: var(--secondary-blue);\n    color: white;\n    font-size: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
