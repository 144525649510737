// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-select-btn{
    position:relative;
}

.img-select-active{
    position: relative;
    outline: 2px solid var(--secondary-blue);
}

.img-select-active::after{
    position:absolute;
    content: '';
    background: rgba(255,255,255,0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.see-more-btn:hover{
    outline: 2px solid var(--secondary-blue);
}`, "",{"version":3,"sources":["webpack://./src/components/Image/selector/imageSelector.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,iCAAiC;IACjC,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;AACX;;AAEA;IACI,wCAAwC;AAC5C","sourcesContent":[".img-select-btn{\n    position:relative;\n}\n\n.img-select-active{\n    position: relative;\n    outline: 2px solid var(--secondary-blue);\n}\n\n.img-select-active::after{\n    position:absolute;\n    content: '';\n    background: rgba(255,255,255,0.3);\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n}\n\n.see-more-btn:hover{\n    outline: 2px solid var(--secondary-blue);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
