// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-lg {
    font-size: 0.75rem;
    padding: 0.75rem 0.8rem;
    font-weight: 550;
    letter-spacing: 1px;
    color: #333;
    border: 1px solid transparent;
}

.button-md {
    font-size: 0.75rem;
    padding: 0.325rem 0.75rem;
    font-weight: 600;
    color: #333;
    border: 1px solid transparent;
}


.button-lg:hover, .button-lg:active, .button-lg:focus, .button-md:hover, .button-md:active, .button-md:focus{
    background: var(--glory-secondary-blue);
    color: white;
    outline: none;
    border: 1px white solid;
}



button:disabled,
button[disabled]{
    background-color: #ccc;
    border-color: #ccc;
    outline: none;
    color: #999;
}


button:disabled:hover,button[disabled]:hover{
    background-color: #ccc;
    border-color: #ccc;
    outline: none;
    color: #999;
} `, "",{"version":3,"sources":["webpack://./src/components/Buttons/base.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;IACX,6BAA6B;AACjC;;;AAGA;IACI,uCAAuC;IACvC,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;;;AAIA;;IAEI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;;AAGA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf","sourcesContent":[".button-lg {\n    font-size: 0.75rem;\n    padding: 0.75rem 0.8rem;\n    font-weight: 550;\n    letter-spacing: 1px;\n    color: #333;\n    border: 1px solid transparent;\n}\n\n.button-md {\n    font-size: 0.75rem;\n    padding: 0.325rem 0.75rem;\n    font-weight: 600;\n    color: #333;\n    border: 1px solid transparent;\n}\n\n\n.button-lg:hover, .button-lg:active, .button-lg:focus, .button-md:hover, .button-md:active, .button-md:focus{\n    background: var(--glory-secondary-blue);\n    color: white;\n    outline: none;\n    border: 1px white solid;\n}\n\n\n\nbutton:disabled,\nbutton[disabled]{\n    background-color: #ccc;\n    border-color: #ccc;\n    outline: none;\n    color: #999;\n}\n\n\nbutton:disabled:hover,button[disabled]:hover{\n    background-color: #ccc;\n    border-color: #ccc;\n    outline: none;\n    color: #999;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
