// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-input input[type='password']{
    font-size: 1.25rem;
    width: 100%
}

.password-input-cn button {
    font-size: 0.8rem !important;
}

label:has(.password-input-cn) {
    align-items: start !important;
}

.password-input-cn:has(input[type='password']:invalid) button {
    outline: 1px solid #BBB;
    background-color: #EEE;
    color: #AAA;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Password/passwordInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB;AACJ;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".password-input input[type='password']{\n    font-size: 1.25rem;\n    width: 100%\n}\n\n.password-input-cn button {\n    font-size: 0.8rem !important;\n}\n\nlabel:has(.password-input-cn) {\n    align-items: start !important;\n}\n\n.password-input-cn:has(input[type='password']:invalid) button {\n    outline: 1px solid #BBB;\n    background-color: #EEE;\n    color: #AAA;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
