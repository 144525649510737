// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-input-cn{
    position: relative;
    outline: 1px solid #ccc;
    padding: 2px 4px;
}

.text-input-cn:has(input:disabled){
    outline-color: #BBB;
    background-color: #EEE;
    pointer-events: none;
}

.text-input{  
    height: 26px;
    outline: none;
    border: none;
    background-color: transparent;
    text-align: start;
    min-width: 0;
}

.text-input-lg{
    height: 36px;
    font-size: 1.25rem;
}

.text-input-rounded:has(img){
    border-radius: 24px;
    padding: 2px 8px 2px 14px;
}

.text-input-rounded{
    border-radius: 24px;
    padding: 2px 14px;
}

.text-input:focus{
    text-align: start;
}


.text-input-cn:has(input:invalid){
    outline: 1px solid;
    outline-color: #DB095B;
}

.text-input-icon{
    height: 20px;
    width: 20px;
    object-fit: contain;
    box-sizing: content-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/textInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".text-input-cn{\n    position: relative;\n    outline: 1px solid #ccc;\n    padding: 2px 4px;\n}\n\n.text-input-cn:has(input:disabled){\n    outline-color: #BBB;\n    background-color: #EEE;\n    pointer-events: none;\n}\n\n.text-input{  \n    height: 26px;\n    outline: none;\n    border: none;\n    background-color: transparent;\n    text-align: start;\n    min-width: 0;\n}\n\n.text-input-lg{\n    height: 36px;\n    font-size: 1.25rem;\n}\n\n.text-input-rounded:has(img){\n    border-radius: 24px;\n    padding: 2px 8px 2px 14px;\n}\n\n.text-input-rounded{\n    border-radius: 24px;\n    padding: 2px 14px;\n}\n\n.text-input:focus{\n    text-align: start;\n}\n\n\n.text-input-cn:has(input:invalid){\n    outline: 1px solid;\n    outline-color: #DB095B;\n}\n\n.text-input-icon{\n    height: 20px;\n    width: 20px;\n    object-fit: contain;\n    box-sizing: content-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
