// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-select{
    display:flex;
    gap: 1rem;
    flex-wrap: nowrap;
}

.check-select-opt{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    border: 2px solid #ddd;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    font-family: 'Raleway';
    gap: 0.5rem;
    color: white;
}

.check-select-opt:hover{
    border: 2px solid var(--glory-blue);
}

.check-select-opt > img {
    object-fit: contain;
    padding: 0.2rem;
    border: 1px solid #ddd;
    background: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/CheckSelect/checkSelect.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IAEtB,gBAAgB;IAChB,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".check-select{\n    display:flex;\n    gap: 1rem;\n    flex-wrap: nowrap;\n}\n\n.check-select-opt{\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n    height: fit-content;\n    width: fit-content;\n    justify-content: center;\n    border: 2px solid #ddd;\n    font-family: 'Raleway';\n    font-weight: 500;\n    border-radius: 0.5rem;\n    padding: 0.5rem 0.75rem;\n    font-family: 'Raleway';\n    gap: 0.5rem;\n    color: white;\n}\n\n.check-select-opt:hover{\n    border: 2px solid var(--glory-blue);\n}\n\n.check-select-opt > img {\n    object-fit: contain;\n    padding: 0.2rem;\n    border: 1px solid #ddd;\n    background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
