// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-img-up-cn:hover > .img-btn-cn{
    display: block !important;
    background: rgba(0,0,0,0.6);
}

.form-img-btn-cn{
    display: none;
    line-height: 100%;
    font-size: 50%;
    font-weight: 600;
}

.form-img-cn > picture > img {
    object-fit: cover !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/FormImage/formImageUpload.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".form-img-up-cn:hover > .img-btn-cn{\n    display: block !important;\n    background: rgba(0,0,0,0.6);\n}\n\n.form-img-btn-cn{\n    display: none;\n    line-height: 100%;\n    font-size: 50%;\n    font-weight: 600;\n}\n\n.form-img-cn > picture > img {\n    object-fit: cover !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
